<template>
  <div class="w-p-100 h-p-100 bg-f3">
    <div class="h-calc-45 scroll-y w-p-100">
      <van-cell title="培训课程" :value="detail.types_1" />
      <van-cell title="培训时间" :value="detail.train_start" />
      <van-cell
        title="培训地点"
        :value="detail.classroom"
        v-if="detail.is_special != 1"
      />
      <van-cell
        title="培训讲师"
        :value="detail.teacher"
        v-if="detail.is_special != 1"
      />
      <van-field
        is-link
        readonly
        v-model="state.types_1"
        name="picker"
        @click="picker.types_1 = true"
        label="题库类型"
        placeholder="必填项"
      />
      <van-field
        is-link
        readonly
        v-model="state.types_2"
        v-if="query.types_1 == 2"
        @click="picker.types_2 = true"
        name="picker"
        label="题库子类型"
        placeholder="必填项"
      />
      <van-field
        is-link
        readonly
        v-model="state.training_type"
        name="picker"
        @click="picker.training_type = true"
        label="初训/复训"
        placeholder="选填"
      />
      <van-field
        is-link
        readonly
        v-model="state.name"
        name="picker"
        @click="picker.name = true"
        label="题库名称"
        placeholder="必填项"
      />

      <!-- 下拉内容 -->
      <van-popup v-model:show="picker.types_1" position="bottom">
        <van-picker
          :columns="options.types_1"
          @confirm="
            data => {
              confirm(data, 'types_1');
            }
          "
          :columns-field-names="{ text: 'label', value: 'value' }"
          @cancel="picker.types_1 = false"
        />
      </van-popup>
      <van-popup v-model:show="picker.types_2" position="bottom">
        <van-picker
          :columns="options.types_2"
          @confirm="
            data => {
              confirm(data, 'types_2');
            }
          "
          :columns-field-names="{ text: 'label', value: 'value' }"
          @cancel="picker.types_2 = false"
        />
      </van-popup>
      <van-popup v-model:show="picker.training_type" position="bottom">
        <van-picker
          :columns="options.training_type"
          @confirm="
            data => {
              confirm(data, 'training_type');
            }
          "
          :columns-field-names="{ text: 'label', value: 'value' }"
          @cancel="picker.training_type = false"
        />
      </van-popup>
      <van-popup v-model:show="picker.name" position="bottom">
        <van-picker
          :columns="options.name"
          @confirm="
            data => {
              confirm(data, 'name');
            }
          "
          :columns-field-names="{ text: 'label', value: 'value' }"
          @cancel="picker.name = false"
        />
      </van-popup>
      <el-table :data="detailList" style="width:100%" border stripe>
        <el-table-column
          label="题型"
          align="center"
          prop="label"
        ></el-table-column>
        <el-table-column
          label="分值"
          align="center"
          prop="score"
        ></el-table-column>
        <el-table-column
          label="数量"
          align="center"
          prop="num"
        ></el-table-column>
        <el-table-column
          label="总分"
          align="center"
          prop="sunScore"
        ></el-table-column>
      </el-table>
    </div>
    <van-button
      block
      type="primary"
      class="bg-0F60A7 color-fff h-45"
      :disabled="isloading"
      :loading="isloading"
      loading-text="提交中"
      @click="onSubmit"
      >生成试卷</van-button
    >
    <!-- <div class="h-35 size-18 w-p-100 flex alignCenter justifyCenter bg-0F60A7 color-fff padding-5" @click="onSubmit">生成试卷</div> -->
  </div>
</template>
<script>
import { onMounted, reactive, ref } from "vue";
import http from "../../../api/http";
import { useRoute, useRouter } from "vue-router";
import { Notify } from "vant";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const query = reactive({
      types_1: "",
      types_2: "",
      training_type: "",
      name: ""
    });
    const detail = reactive({
      types_1: "",
      types_2: "",
      teacher: "",
      train_start: "",
      classroom: "",
      is_special: ""
    });
    const state = reactive({
      types_1: "",
      types_2: "",
      training_type: "",
      name: ""
    });
    const options = reactive({
      types_1: [],
      types_2: [],
      training_type: [],
      name: []
    });
    const getSelectList = () => {
      http
        .get(
          {
            method: "listMultiPardata",
            t: "CT_QUESTION_TEST",
            prop: "types_1,types_2,training_type",
            types_1: query.types_1
          },
          false
        )
        .then(res => {
          options.types_1 = res.types_1;
          options.types_2 = res.types_2;
          options.training_type = res.training_type;
        });
    };
    // 获取详情
    const getLesson = () => {
      http
        .get({
          method: "query",
          queryId: 1069,
          ct_train_lesson_id: route.params.id
        })
        .then(res => {
          detail.types_1 = res.types_1;
          detail.types_2 = res.types_2;
          detail.teacher = res.teacher;
          detail.train_start = res.train_start_date;
          detail.classroom = res.classroom;
          detail.is_special = res.is_special;
        })
        .catch(() => {});
    };
    // 获取试题下拉
    const getQuestionNameList = () => {
      http
        .get({
          method: "query",
          queryId: 1000,
          t: "CT_QUESTION_TEST",
          types_1: query.types_1,
          types_2: query.types_2,
          training_type: query.training_type
        })
        .then(res => {
          query.name = "";
          state.name = "";
          var list = [];
          res.map.forEach(element => {
            list.push({ value: element.id, label: element.name });
          });
          options.name = list;
        });
    };

    // 详情
    const detailList = ref([]);
    const getQuestionDetail = () => {
      http
        .get({
          method: "detail",
          t: "CT_QUESTION_TEST",
          id: query.name
        })
        .then(res => {
          var list = [],
            dTail = res.map;
          var num1 = dTail.num_1 != null && dTail.num_1 != "" ? dTail.num_1 : 0;
          var num2 = dTail.num_2 != null && dTail.num_2 != "" ? dTail.num_2 : 0;
          var num3 = dTail.num_3 != null && dTail.num_3 != "" ? dTail.num_3 : 0;
          var num4 = dTail.num_4 != null && dTail.num_4 != "" ? dTail.num_4 : 0;
          var score1 =
            dTail.score_1 != null && dTail.score_1 != "" ? dTail.score_1 : 0;
          var score2 =
            dTail.score_2 != null && dTail.score_2 != "" ? dTail.score_2 : 0;
          var score3 =
            dTail.score_3 != null && dTail.score_3 != "" ? dTail.score_3 : 0;
          var score4 =
            dTail.score_4 != null && dTail.score_4 != "" ? dTail.score_4 : 0;
          list.push({
            label: "总计",
            num: Number(num1) + Number(num2) + Number(num3) + Number(num4),
            score: "", //Number(score1) + Number(score2) + Number(score3) + Number(score4),
            sunScore:
              Number(num1) * Number(score1) +
              Number(num2) * Number(score2) +
              Number(num3) * Number(score3) +
              Number(num4) * Number(score4)
          });
          if (dTail.num_4 > 0) {
            list.push({
              label: "简答题",
              num: Number(num4),
              score: Number(score4),
              sunScore: Number(num4) * Number(score4)
            });
          }
          if (dTail.num_3 > 0) {
            list.push({
              label: "单选题",
              num: Number(num3),
              score: Number(score3),
              sunScore: Number(num3) * Number(score3)
            });
          }
          if (dTail.num_2 > 0) {
            list.push({
              label: "多选题",
              num: Number(num2),
              score: Number(score2),
              sunScore: Number(num2) * Number(score2)
            });
          }
          if (dTail.num_1 > 0) {
            list.push({
              label: "判断题",
              num: Number(num1),
              score: Number(score1),
              sunScore: Number(num1) * Number(score1)
            });
          }
          detailList.value = list;
        });
    };
    const picker = reactive({
      types_1: false,
      types_2: false,
      training_type: false,
      name: false
    });
    const confirm = (data, type) => {
      query[type] = data.value;
      state[type] = data.label;
      picker[type] = false;
      if (type == "types_1") {
        query.types_2 = "";
        state.types_2 = "";
        getSelectList();
      }
      if (type != "name") {
        getQuestionNameList();
      }
      if (type == "name") {
        getQuestionDetail();
      }
    };
    const isloading = ref(false);
    const onSubmit = () => {
      if (query.name != "") {
        isloading.value = true;
        http
          .get({
            method: "query",
            queryId: 1060,
            ct_question_test_id: query.name,
            ct_train_lesson_id: route.params.id
          })
          .then(() => {
            isloading.value = false;
            router.push("/" + route.meta.parent);
          })
          .catch(() => {
            isloading.value = false;
          });
      } else {
        Notify({ type: "danger", message: "请选择题库！", duration: 2000 });
      }
    };

    onMounted(() => {
      getSelectList();
      getQuestionNameList();
      getLesson();
    });
    return {
      query,
      options,
      picker,
      onSubmit,
      confirm,
      state,
      detailList,
      detail,
      route,
      isloading
    };
  }
};
</script>
